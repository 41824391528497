var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.STATUS')} (*)`,"placeholder":_vm.$t('COMMON.STATUS')}},[_c('el-select',{attrs:{"name":"status","placeholder":"status"},on:{"change":(status) => {
              _vm.equipment.status = status;
              _vm.onFormChanged();
            }},model:{value:(_vm.equipment.status),callback:function ($$v) {_vm.$set(_vm.equipment, "status", $$v)},expression:"equipment.status"}},_vm._l((_vm.statusesOption),function(label,val){return _c('el-option',{key:val,attrs:{"value":val,"label":label}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.status}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-checkbox',{staticClass:"my-5",model:{value:(_vm.equipment.is_loan),callback:function ($$v) {_vm.$set(_vm.equipment, "is_loan", $$v)},expression:"equipment.is_loan"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" Prêté par le fournisseur ")])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.is_loan}})],1)]),(_vm.equipment.is_loan)?_c('div',[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS) && !_vm.hideOrganization)?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SUPPLIER')}`,"placeholder":_vm.$t('COMMON.SUPPLIER')}},[_c('supplier-selector',{attrs:{"filterOrganization":_vm.equipment.organization?.id,"filterable":true,"showAll":false},on:{"cafeteriaChanged":(cafeteriaId) => {
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cafeteria}})],1):_vm._e(),(_vm.equipment.is_loan)?_c('base-input',{attrs:{"label":`${_vm.$t('EQUIPMENTS.LOAN_YEAR')} (*)`,"placeholder":_vm.$t('EQUIPMENTS.LOAN_YEAR')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        enableTime: true,
        dateFormat: 'Y-m-d',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.equipment.loan_year),callback:function ($$v) {_vm.$set(_vm.equipment, "loan_year", $$v)},expression:"equipment.loan_year"}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.loan_year}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) && !_vm.hideOrganization
        )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.equipment.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.equipment.id},on:{"organizationChanged":(organizationId) => {
              _vm.equipment.organization.id = organizationId;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
          !_vm.hideOrganization
        )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CATEGORY_EQUIPMENTS')} (*)`,"placeholder":_vm.$t('COMMON.CATEGORY_EQUIPMENTS')}},[_c('category-equipment-selector',{attrs:{"categoryEquipment":_vm.equipment.categoryEquipment?.id,"filterable":true,"showAll":false},on:{"categoryEquipmentValChanged":(categoryEquipmentId, categoryEquipment) => {
              _vm.equipment.categoryEquipment.id = categoryEquipmentId;
              _vm.categoryEquipmentModel = categoryEquipment;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serviceCenter}})],1)]),_c('base-input',{attrs:{"label":`${_vm.$t('EQUIPMENTS.PURCHASE_YEAR')} (*)`,"placeholder":_vm.$t('EQUIPMENTS.PURCHASE_YEAR')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        enableTime: true,
        dateFormat: 'Y-m-d',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":() => {
          _vm.onFormChanged();
        }},model:{value:(_vm.equipment.purchase_year),callback:function ($$v) {_vm.$set(_vm.equipment, "purchase_year", $$v)},expression:"equipment.purchase_year"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.purchase_year}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"required":true},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.name),callback:function ($$v) {_vm.$set(_vm.equipment, "name", $$v)},expression:"equipment.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.QUANTITY')} (*)`,"required":true},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.quantity),callback:function ($$v) {_vm.$set(_vm.equipment, "quantity", $$v)},expression:"equipment.quantity"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.quantity}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CODE')} (*)`,"required":true},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.code),callback:function ($$v) {_vm.$set(_vm.equipment, "code", $$v)},expression:"equipment.code"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.code}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.BRAND')} (*)`,"required":true},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.brand),callback:function ($$v) {_vm.$set(_vm.equipment, "brand", $$v)},expression:"equipment.brand"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.brand}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SERIAL_NUMBER')}`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.serial_number),callback:function ($$v) {_vm.$set(_vm.equipment, "serial_number", $$v)},expression:"equipment.serial_number"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serial_number}})],1)]),(
      _vm.showCashRegisterTerminalPointField ||
      _vm.showKitchenField ||
      _vm.showOfficeEquipmentField ||
      _vm.showSealerField ||
      _vm.showDisplayField ||
      _vm.showCareProductField
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('EQUIPMENTS.MODEL')}`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.model),callback:function ($$v) {_vm.$set(_vm.equipment, "model", $$v)},expression:"equipment.model"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.model}})],1)]):_vm._e(),(
      _vm.showVendingMachineField ||
      _vm.showCashRegisterTerminalPointField ||
      _vm.showOfficeEquipmentField ||
      _vm.showSealerField ||
      _vm.showDisplayField ||
      _vm.showCareProductField
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('EQUIPMENTS.SERVICE_PLAN')}`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.service_plan),callback:function ($$v) {_vm.$set(_vm.equipment, "service_plan", $$v)},expression:"equipment.service_plan"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.service_plan}})],1)]):_vm._e(),(_vm.showKitchenField)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('EQUIPMENTS.FORMAT')}`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.format),callback:function ($$v) {_vm.$set(_vm.equipment, "format", $$v)},expression:"equipment.format"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.format}})],1)]):_vm._e(),(_vm.showCashRegisterTerminalPointField || _vm.showOfficeEquipmentField)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('EQUIPMENTS.SOFTWARE')}`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.software),callback:function ($$v) {_vm.$set(_vm.equipment, "software", $$v)},expression:"equipment.software"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.software}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.GUARANTEE')}`},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.equipment.guarantee),callback:function ($$v) {_vm.$set(_vm.equipment, "guarantee", $$v)},expression:"equipment.guarantee"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.guarantee}})],1)]),_c('div',{},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NOTE')}`,"placeholder":_vm.$t('COMMON.NOTE')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.equipment.excerpt),callback:function ($$v) {_vm.$set(_vm.equipment, "excerpt", $$v)},expression:"equipment.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-checkbox',{staticClass:"my-5",model:{value:(_vm.equipment.is_internal),callback:function ($$v) {_vm.$set(_vm.equipment, "is_internal", $$v)},expression:"equipment.is_internal"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" Propriétaire Laniel")])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.is_internal}})],1)]),_c('div',[(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS) && !_vm.hideOrganization
      )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.SERVICECENTER')}`,"placeholder":_vm.$t('COMMON.SERVICECENTER')}},[_c('service-center-selector',{attrs:{"filterOrganization":_vm.equipment.organization?.id,"serviceCenter":_vm.equipment.serviceCenter?.id,"filterable":true,"showAll":false},on:{"serviceCenterChanged":(serviceCenterId) => {
            _vm.equipment.serviceCenter.id = serviceCenterId;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.serviceCenter}})],1),_c('div',[(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS) && !_vm.hideOrganization
      )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ESTABLISHMENT')}`,"placeholder":_vm.$t('COMMON.ESTABLISHMENT')}},[_c('establishment-selector',{attrs:{"filterOrganization":_vm.equipment.organization?.id,"establishment":_vm.equipment.establishment?.id,"filterable":true,"filterServiceCenter":_vm.equipment.serviceCenter?.id,"showAll":false},on:{"establishmentChanged":(establishmentId) => {
            _vm.equipment.establishment.id = establishmentId;
            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishment}})],1),_c('div',[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS) && !_vm.hideOrganization)?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CAFETERIA')}`,"placeholder":_vm.$t('COMMON.CAFETERIA')}},[_c('cafeteria-selector',{attrs:{"filterOrganization":_vm.equipment.organization?.id,"cafeteria":_vm.equipment.cafeteria?.id,"filterEstablishment":_vm.equipment.establishment?.id,"filterable":true,"showAll":false},on:{"cafeteriaChanged":(cafeteriaId) => {
            _vm.equipment.cafeteria.id = cafeteriaId;

            _vm.onFormChanged();
          }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cafeteria}})],1),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.equipment.id ? _vm.$t("EQUIPMENTS.EDIT_EQUIPMENT") : _vm.$t("EQUIPMENTS.ADD_EQUIPMENT"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }